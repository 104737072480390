<template>
  <HelpComponent>
    <b-row class="mb-3">
      <b-col>
        Cet &eacute;cran vous permet de v&eacute;rifier les
        <strong>quantit&eacute;s livr&eacute;es / encore attendues</strong>
        par votre coop&eacute;rative. Les informations sur la campagne en cours
        de saisie apparaissent <strong>d&eacute;but juin</strong>.
      </b-col>
    </b-row>
    <b-row class="mb-3">
      <b-col>
        La colonne "Certificat Bio" vous indique si la coop&eacute;rative
        dispose d'un certificat Bio valide de votre part pour l'esp&egrave;ce
        concern&eacute;e.
      </b-col>
    </b-row>
    <InfoBloc>
      Si ce n'est pas/plus le cas, vous pouvez transmettre votre nouveau
      certificat &agrave; votre service logistique en le d&eacute;posant sur
      <b-link :to="{ name: 'certificats' }">cette page</b-link>.
    </InfoBloc>
    <b-row>
      <b-col>
        Vous pouvez contacter le service logistique de votre coop&eacute;rative
        si vous constatez une erreur dans les quantit&eacute;s attendues.
      </b-col>
    </b-row>
  </HelpComponent>
</template>

<script>
import HelpComponent from "./HelpComponent.vue";
import InfoBloc from "../blocs/InfoBloc.vue";
export default {
  name: "HelpContratApportComponent",
  components: { HelpComponent, InfoBloc },
};
</script>
