<template>
  <section>
    <TitleBar>
      Suivi de la collecte
      <template #toolbar-content>
        <HelpContratApportComponent></HelpContratApportComponent>
        &nbsp;
        <TableButtonGroup
          :tableKey="tableKey"
          @rechercher="rechercher"
        ></TableButtonGroup>
      </template>
    </TitleBar>
    <b-container fluid>
      <ErrorComponent :error="error" @hide="error = null"></ErrorComponent>
      <StrapiEditoComponent
        :societe="exploitationCourante.codeSociete"
      ></StrapiEditoComponent>
      <PaginatedTable
        :fields="fields"
        :busy="busy"
        :pageable="pageable"
        :page="page"
        @change="pageableChanged"
      >
        <template #mobile-card="{ item }">
          <ContratApportCard
            :item="item"
            :variant="statutVariants.get(item.libelleStatut)"
          ></ContratApportCard>
        </template>
        <template #cell(culture)="{ item }">
          {{ getLibelleProduit(item.codeProduit) }}
        </template>
        <template #cell(certificatBioOk)="{ item }">
          <b-icon-check-circle-fill
            v-if="item.certificatBioOk"
            variant="success"
          >
          </b-icon-check-circle-fill>
          <b-icon-x-circle-fill v-if="!item.certificatBioOk" variant="danger">
          </b-icon-x-circle-fill>
        </template>
        <template #cell(quantiteContrat)="{ item }">
          <MontantComponent :value="item.quantiteContrat"></MontantComponent>
        </template>
        <template #cell(quantiteLivree)="{ item }">
          <MontantComponent :value="item.quantiteLivree"></MontantComponent>
        </template>
        <template #cell(quantiteSolde)="{ item }">
          <MontantComponent :value="item.quantiteSolde"></MontantComponent>
        </template>
        <template #cell(libelleStatut)="{ item }">
          <span
            :class="`font-weight-bold text-${statutVariants.get(
              item.libelleStatut
            )}`"
          >
            {{ item.libelleStatut }}
          </span>
        </template>
      </PaginatedTable>
    </b-container>
  </section>
</template>

<script>
import TitleBar from "../../components/TitleBar.vue";
import HelpContratApportComponent from "../../components/controls/HelpContratApportComponent.vue";
import TableButtonGroup from "../../components/controls/TableButtonGroup.vue";
import ErrorComponent from "../../components/ErrorComponent.vue";
import StrapiEditoComponent from "../../components/strapi/StrapiEditoComponent.vue";
import PaginatedTable from "../../components/PaginatedTable.vue";
import ContratApportCard from "../../components/cards/ContratApportCard.vue";
import MontantComponent from "../../components/MontantComponent.vue";
import ExploitationService from "../../services/exploitation.service";
import { TableKeys } from "../../store/tables.defaults";
import {
  dataFactory as tableDataFactory,
  created,
  computed as tableComputed,
  methods as tableMethods,
} from "../../services/tables.service";
import UtilsService from "../../services/utils.service";
export default {
  name: "ContratsApportView",
  components: {
    TitleBar,
    HelpContratApportComponent,
    TableButtonGroup,
    ErrorComponent,
    StrapiEditoComponent,
    PaginatedTable,
    ContratApportCard,
    MontantComponent,
  },
  data() {
    return {
      ...tableDataFactory(TableKeys.CONTRATS_APPORT),
      error: null,
      page: null,
      statutVariants: new Map()
        .set("Soldé", "success")
        .set("En cours", "warning")
        .set("En attente", "warning")
        .set("Annulé", "danger"),
    };
  },
  computed: {
    ...tableComputed,
    exploitationCourante() {
      return this.$store.getters["expl/adherentCourant"];
    },
  },
  created,
  mounted() {
    this.load();
  },
  methods: {
    ...tableMethods,
    async load() {
      this.error = null;
      try {
        this.busy = true;
        let response = await ExploitationService.getContratsApport(
          this.exploitationCourante.codeTiers,
          this.getSearchPayload()
        );
        this.page = response.data;
      } catch (error) {
        this.page = null;
        this.error = UtilsService.handleError(error);
      } finally {
        this.busy = false;
      }
    },
    getLibelleProduit(codeProduit) {
      return (
        this.$store.getters["ref/produitCereale"](codeProduit)?.libelle ?? ""
      );
    },
  },
};
</script>
