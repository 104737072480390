<template>
  <b-card class="mobile-card">
    <b-container v-b-toggle="`contrat_${item.idKerhis}`">
      <b-row align-v="center">
        <b-col cols="auto" class="bg-light bg-left font-up" style="width: 90px">
          <div :class="`w-100 text-${variant}`">
            {{ item.libelleStatut }}<br />
            <b-icon-x-circle-fill v-if="!item.certificatBioOk" variant="danger">
            </b-icon-x-circle-fill>
          </div>
        </b-col>
        <b-col>
          <div class="font-down">Campagne {{ item.codeRecolte }}</div>
          <div>{{ libelleProduit }}</div>
          <div class="font-down">
            <b-progress height="5px" :max="max">
              <template v-if="pending">
                <b-progress-bar
                  :value="item.quantiteLivree"
                  variant="success"
                ></b-progress-bar>
                <b-progress-bar
                  :value="item.quantiteSolde"
                  variant="warning"
                  animated
                ></b-progress-bar>
              </template>
              <template v-else>
                <b-progress-bar
                  :value="Math.min(item.quantiteContrat, item.quantiteLivree)"
                  variant="success"
                ></b-progress-bar>
                <b-progress-bar
                  :value="Math.abs(ecart)"
                  variant="danger"
                ></b-progress-bar>
              </template>
            </b-progress>
          </div>
          <b-collapse
            :id="`contrat_${item.idKerhis}`"
            class="label font-down text-center"
            visible
          >
            <b-icon-chevron-down></b-icon-chevron-down>
            D&eacute;tails
          </b-collapse>
          <b-collapse :id="`contrat_${item.idKerhis}`" class="font-down">
            <b-row class="m-0 mt-2">
              <b-col cols="3" class="p-0">Attendu</b-col>
              <b-col cols="9" class="p-0">
                <MontantComponent
                  :value="item.quantiteContrat"
                  :options="{ minimumFractionDigits: 0 }"
                ></MontantComponent>
                t
              </b-col>
              <b-col cols="3" class="p-0">Livr&eacute;</b-col>
              <b-col cols="3" class="p-0">
                <MontantComponent
                  :value="item.quantiteLivree"
                  :options="{ minimumFractionDigits: 0 }"
                ></MontantComponent>
                t
              </b-col>
              <template v-if="!pending">
                <b-col cols="3" class="p-0 text-danger">Ecart</b-col>
                <b-col cols="3" class="p-0 text-danger">
                  <MontantComponent
                    :value="ecart"
                    :options="{
                      minimumFractionDigits: 0,
                      signDisplay: 'exceptZero',
                    }"
                  ></MontantComponent>
                  t
                </b-col>
              </template>
              <template v-else>
                <b-col cols="3" class="p-0 text-warning">Solde</b-col>
                <b-col cols="3" class="p-0 text-warning">
                  <MontantComponent
                    :value="item.quantiteSolde"
                    :options="{ minimumFractionDigits: 0 }"
                  ></MontantComponent>
                  t
                </b-col>
              </template>
            </b-row>
            <b-row v-if="!item.certificatBioOk" class="m-0 mt-2">
              <b-col cols="auto" class="p-0">
                <b-icon-x-circle-fill variant="danger"></b-icon-x-circle-fill>
              </b-col>
              <b-col class="p-0 pl-2">
                <strong class="text-danger">
                  Certificat Bio &agrave; transmettre &agrave; votre
                  coop&eacute;rative
                </strong>
              </b-col>
            </b-row>
          </b-collapse>
        </b-col>
      </b-row>
    </b-container>
  </b-card>
</template>

<script>
import MontantComponent from "../MontantComponent.vue";
export default {
  name: "ContratApportCard",
  components: { MontantComponent },
  props: ["item", "variant"],
  computed: {
    libelleProduit() {
      return (
        this.$store.getters["ref/produitCereale"](this.item.codeProduit)
          ?.libelle ?? this.item.codeProduit
      );
    },
    pending() {
      // Indique si le contrat n'est ni soldé ni annulé
      // FIXME Il va falloir faire un poil plus propre qu'utiliser la variante
      return this.variant !== "success" && this.variant !== "danger";
    },
    max() {
      return (
        Math.max(
          this.item.quantiteLivree + this.item.quantiteSolde,
          this.item.quantiteContrat
        ) || 1
      );
    },
    ecart() {
      return (this.item.quantiteLivree - this.item.quantiteContrat).toFixed(2);
    },
  },
};
</script>
